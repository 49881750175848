import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { useMediaQuery } from "react-responsive";
import Page from "../components/Page";
import Seo from "../components/seo";
import Aside from "../components/Aside";
import * as P from "../components/Page/styles";
import Icon from "../components/Icons/icon";
import SubAreas from "../components/SubAreas";

const LaborLaw = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Direito Trabalhista"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          { page: "/direito-trabalhista", name: "Direito Trabalhista" },
        ]}
      >
        {isDesktop && <Aside type="labor-law" withContact />}

        <P.Article>
          <p>
            Existem dezenas de direitos que todo trabalhador possui, você
            gostaria de conhecer alguns deles? Então nos acompanhe nesta
            leitura.{" "}
          </p>

          <p>
            Mas se você já sabe o que está procurando e em qual direito você
            pode ter sido lesado, nos mande uma mensagem direta pelo{" "}
            <P.ContactLink
              href="https://wa.me/5561983002735"
              target="_blank"
              rel="noreferrer"
            >
              WhatsApp
            </P.ContactLink>
            , conte-nos sua história e nos deixe ajuda-lo.
          </p>

          <P.Subtitle>Registro em carteira de trabalho</P.Subtitle>

          <p>
            De acordo com a CLT, após a admissão de um colaborador, a empresa
            tem um prazo de 48 horas para realizar as devidas anotações na
            Carteira de Trabalho e Previdência Social (CTPS), informando a data
            de admissão, função e remuneração.
          </p>

          <p>
            Vale destacar que hoje existe a carteira de trabalho digital, e por
            este motivo, não é preciso, necessariamente, realizar a baixa na
            carteira física, podendo ser realizada na digital e tendo a mesma
            validade.
          </p>

          <p>
            A CTPS digital é emitida automaticamente, no mesmo instante em que a
            pessoa faz o seu cadastro de pessoa física, ou seja, registra o seu
            CPF, e o futuro nos promete que a CTPS física, muito em breve
            deixará de existir.
          </p>

          <P.Subtitle>Pagamento de salário em dia</P.Subtitle>

          <p>
            De acordo com as leis trabalhistas, o pagamento do colaborador deve
            ser realizado sempre até o quinto dia útil de cada mês. Caso ocorra
            atraso, a empresa está sujeita ao pagamento de multas e pode ser
            alvo de processos trabalhistas. Essa multa poderá ser dobrada, em
            caso de reincidência.
          </p>

          <P.Subtitle>Auxílio Transporte</P.Subtitle>

          <p>
            A CLT garante o direito ao recebimento de vale-transporte, que
            consiste no adiantamento do valor das despesas do colaborador para
            se locomover da sua casa até a empresa. Ainda, porém, sem sempre é
            vantajoso ao colaborador receber o vale transporte, pois a empresa
            pode descontar até 6% do valor do seu salário, ou seja, se o seu
            gasto com transporte é menor que 6% do salário, é bem mais vantajoso
            abrir mão deste benefício.
          </p>

          <p>
            Vale destacar que, se você trabalha de forma remota (home office),
            não existe mais a obrigatoriedade de pagar esse auxílio, pois o
            colaborador não precisará mais se deslocar de casa para a empresa,
            logo. Não necessita do benefício.
          </p>

          <P.Subtitle>Folga remunerada</P.Subtitle>

          <p>
            O Art. 67 da CLT prescreve que todo trabalhador tem direito a
            receber uma folga por semana, e que essa folga poderá ser objeto de
            acordo entre o colaborador e a empresa, porém a preferência é que
            esta folga seja gozada aos domingos. Veja o que ao artigo diz a lei:
          </p>

          <P.Citation>
            <Icon name="Quotes" />
            <p>
              Art. 67/CLT - Será assegurado a todo empregado um descanso semanal
              de 24 (vinte e quatro) horas consecutivas, o qual, salvo motivo de
              conveniência pública ou necessidade imperiosa do serviço, deverá
              coincidir com o domingo, no todo ou em parte.
            </p>
          </P.Citation>

          <p>
            Mas veja, que o trabalho ao domingo não depende exclusivamente do
            empregador, pois ele precisa apresentar uma necessidade imperiosa
            para que o trabalho seja exercido aos domingos, e vale ressaltar
            ainda que o trabalhador não deve trabalhar em todos os domingos, mas
            que deve haver escala.
          </p>

          <P.Subtitle>13º salário</P.Subtitle>

          <p>
            O décimo terceiro salário é uma gratificação natalina, de um salário
            extra, que é pago todos anos ao trabalhador pela empresa, no mês de
            dezembro.
          </p>

          <p>
            A regra determinada pela CLT é que o 13º seja dividido em duas
            parcelas, sendo a primeira depositada até o fim de novembro.
          </p>

          <P.Subtitle>Férias</P.Subtitle>

          <p>
            Prescreve o artigo 129 da CLT: “Todo empregado terá direito,
            anualmente, ao gozo de um período de férias, sem prejuízo da
            remuneração”.
          </p>

          <p>
            Logo, as férias nada mais são que um período de descanso concedido
            ao colaborador após um ano trabalhado. Assim, tanto as férias
            individuais quanto as coletivas, devem ocorrer em períodos
            determinados pelo empregador.
          </p>

          <P.Subtitle>Horas extras</P.Subtitle>

          <p>
            Sempre que um colaborador excede sua carga horária diária, as horas
            extras devem ser pagas pela empresa, com o acréscimo mínimo de 50%
            nos dias úteis. Entretanto, quando elas ocorrem em domingos e
            feriados, o acréscimo deverá ser de 100%.
          </p>

          <p>
            Algumas empresas, podem, em contrato com os funcionários, criar o
            chamado banco de horas, onde os trabalhadores ao invés de receberem
            os valores pelas horas extras, podem tirar essas horas em formato de
            folgas.
          </p>

          <p>
            O banco de horas é uma flexibilização autorizada pela Lei n° 9.601
            de 1998, responsável por alterar a CLT. Ainda, a norma autoriza a
            possibilidade de colaborador e empresa, em comum acordo, obterem um
            banco com horas trabalhadas com o objetivo de compensação no futuro.
            De acordo com a reforma trabalhista, atualmente, esse sistema pode
            ser utilizado por qualquer segmento empresarial.
          </p>

          <P.Subtitle>1. Estabilidade por acidente de trabalho</P.Subtitle>

          <p>
            Se o colaborador tiver um acidente durante a jornada de trabalho,
            ele tem estabilidade de um ano garantido por lei. Essa é uma medida
            que protege o profissional em um momento de maior fragilidade,
            garantindo que ele não fique desamparado.
          </p>

          <p>
            Se por um acaso esse acidente diminuir a capacidade motora do
            trabalhador, este também terá direito ao Aux. Acidente, que é um
            benefício do INSS que é pago mesmo para aquela pessoa que volta a
            trabalhar, e que acumula com o seu salário.
          </p>

          <P.Subtitle>2. Adicional noturno</P.Subtitle>

          <p>
            Todo colaborador que trabalhe entre 22 horas e 5 horas da manhã tem
            direito ao adicional noturno de 20% sobre o valor da hora diurna.
          </p>

          <p>
            O artigo 73 da CLT afirma que a remuneração do trabalhador noturno
            deve ser maior que a do trabalhador diurno.
          </p>

          <P.Subtitle>3. Faltas descontadas</P.Subtitle>

          <p>
            É fundamental que o RH saiba que nem todas as faltas podem ser
            descontadas do salário dos colaboradores. Dentre elas estão as
            causadas por casamento, doação de sangue, alistamento eleitoral,
            convocação na Justiça e por motivo de doença, quando comprovada por
            atestado médico.
          </p>

          <p>
            Destaca-se ainda, que a jurisprudência do TST vem entendendo que nem
            mesmo o vale refeição deve ser descontado, desde que as faltas sejam
            resultantes de doença com atestado médico.
          </p>

          <p>
            Existem muitos outros direitos que o trabalhador possui, e cada um
            deles é tratado caso a caso, se você acha que algum direito seu foi
            lesado, entre em contato e nos deixe ajuda-lo.
          </p>
        </P.Article>

        {!isDesktop && <Aside type="labor-law" withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Direito Trabalhista"
    description="Especialistas em Direito Trabalhista, oferecendo suporte para questões trabalhistas como demissões, aposentadoria, benefícios e direitos do trabalhador, com atendimento confiável e eficiente."
  />
);

export default LaborLaw;
